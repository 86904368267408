.flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > :not(:first-child) {
        margin-left: 1rem;
    }
}

.anchor {
    margin-top: -@nav-height;
    padding-top: @nav-height;
}
