.events {
    margin: 0;
    padding: 0;

    > li {
        display: block;
    }
}

.event-preview {
    font-size: @font-size-small;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
