.section {
    padding: 1rem 0;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 2px;

    > h2 {
        margin: 0 0 1rem 0;
        font-size: 1.15rem;
        line-height: 1;
        color: @color-text-extralight;
        text-transform: uppercase;
    }
}

.section-group {
    background-color: @gray-lightest;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.section-publish {
    background-color: @gray-lightest;
    margin-top: 3rem;
    padding: 1rem;

    .media-loading:before {
        background-color: fadeout(@gray-lightest, 25%);
    }
}

.section-highlight {
    padding: 1.6rem 0;

    &:before,
    &:after {
        &:extend(.decoration-striped);
        content: " ";
        position: absolute;
        left: 0; right: 0;
        height: .6rem;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}
